export const Color = {
  Accent: '#08c666',
  AccentAlternate: '#ffc501',
  AccentAlternate2: '#7c4bff',
  AccentAlternate3: '#ff2d5c',
  Background: '#171717',
  BackgroundAlternate: '#0e232e',
  BackgroundAlternate2: '#111011',
  BackgroundModal: '#fff',
  BackgroundSecondary: '#232323',
  Border: '#313d4f',
  Overlay: 'rgba(35, 35, 35, 0.9)',
  TextInput: '#111',
  TextPrimary: '#fff',
  TextPrimaryAlternate: '#b5b6b9',
  TextSecondary: '#999',
  TextSecondaryAlternate: '#ccc'
};
