import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import styles from './styles.less';

const root = window.document.querySelector(':root');

const Modal = ({children, isCloseOnClickDisabled, isVisible, onCloseRequested}) => {
  const refOverlay = useRef(null);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    root.style.overflow = 'hidden';

    return () => {
      root.style.overflow = 'initial';
    };
  }, [isVisible]);

  useEffect(() => {
    const overlay = refOverlay.current;

    if (!overlay) {
      return;
    }

    const handleKeyDown = e => {
      if (onCloseRequested && (e.key === 'Escape')) {
        onCloseRequested();
      }
    };

    if (overlay) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (overlay) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  const handleClick = e => {
    e.stopPropagation();

    if (isCloseOnClickDisabled || (e.target !== refOverlay.current)) {
      return;
    }

    if (onCloseRequested) {
      onCloseRequested();
    }
  };

  const componentStyle = isVisible ? {} : {display: 'none'};

  return (
    <div ref={refOverlay} className={styles.overlay} style={componentStyle} onClick={handleClick}>
      <div className={styles.modal}>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isCloseOnClickDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCloseRequested: PropTypes.func
};

Modal.defaultProps = {
  isCloseOnClickDisabled: false,
  isVisible: false,
  onCloseRequested: null
};

export default Modal;
