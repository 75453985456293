// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes Dn3GATYl{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes Dn3GATYl{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}._1f8_SsX4{-js-display:flex;display:flex;flex-direction:column;justify-content:center;align-items:center}._11LYe_tW{-webkit-animation:Dn3GATYl .8s linear infinite;animation:Dn3GATYl .8s linear infinite;margin:0 auto;border-radius:50%;border:5px solid #08c666;border-right-color:transparent}._11LYe_tW._3bPOWVXy{height:64px;width:64px}._11LYe_tW._2RNx13oy{height:32px;width:32px}._3FNs-MYv{margin-top:16px;font-family:Rubik,sans-serif;font-size:16px;color:#08c666}", ""]);
// Exports
exports.locals = {
	"component": "_1f8_SsX4",
	"spinner": "_11LYe_tW",
	"rotate": "Dn3GATYl",
	"component-normal": "_3bPOWVXy",
	"component-small": "_2RNx13oy",
	"text": "_3FNs-MYv"
};
module.exports = exports;
