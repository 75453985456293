// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _2flJQJS3{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes _2flJQJS3{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.kce5SPdK{position:fixed;z-index:3000;top:0;left:0;height:100%;width:100%;-js-display:flex;display:flex;justify-content:center;align-items:center;background-color:rgba(35,35,35,.9);cursor:default}", ""]);
// Exports
exports.locals = {
	"component": "kce5SPdK",
	"rotate": "_2flJQJS3"
};
module.exports = exports;
