import {useEffect, useState} from 'react';

import {measureElement} from '../lib/helper';

const ELEMENT_MAP = new Map();

window.addEventListener('resize', () => {
  ELEMENT_MAP.forEach((setSize, elementRef) => {
    if (!elementRef.current) {
      return;
    }

    setSize(measureElement(elementRef.current));
  });
});

const useElementSize = (elementRef, defaultSize = {width: 0, height: 0}) => {
  const [size, setSize] = useState(defaultSize);

  useEffect(() => {
    ELEMENT_MAP.set(elementRef, setSize);

    if (elementRef.current) {
      setSize(measureElement(elementRef.current));
    }

    let timer = setTimeout(() => {
      timer = null;

      if (elementRef.current) {
        setSize(measureElement(elementRef.current));
      }
    }, 100);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }

      ELEMENT_MAP.delete(elementRef);
    };
  }, [elementRef]);

  return [size];
};

export default useElementSize;
