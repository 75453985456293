// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3PZO_Hs2{margin-top:20px}._3Iz4pVIr{position:absolute;right:-30px;top:-20px;filter:invert(1)}@media (max-width:800px){._3Iz4pVIr{right:-10px;top:-5px}}.mJx9d8C6{position:relative;width:500px}@media (max-width:800px){.mJx9d8C6{width:100%}}._3M36S6Pq+._3M36S6Pq{margin-top:16px}.RX8Pefpw{margin-top:57px;width:100%}@media (max-width:800px){.RX8Pefpw{margin-top:20px}}._2OizPb6a{font-weight:700;font-size:24px;line-height:28px;color:#08c666;text-align:center}._89O7yDlH{margin-top:38px}", ""]);
// Exports
exports.locals = {
	"button": "_3PZO_Hs2",
	"close-button": "_3Iz4pVIr",
	"component": "mJx9d8C6",
	"field": "_3M36S6Pq",
	"fields": "RX8Pefpw",
	"title": "_2OizPb6a",
	"upload-field": "_89O7yDlH"
};
module.exports = exports;
