// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2aLNPJ8j{position:relative;min-height:64px;width:100%;-js-display:flex;display:flex;flex-direction:column;justify-content:flex-end}._1appFpmx{height:30px;border:none;border-bottom:1.31726px solid #999;font-size:21.0762px;line-height:25px;color:#111;padding:0}._1appFpmx:focus{outline:0}._1appFpmx::-moz-placeholder{font-size:18.4416px;line-height:22px;color:#999}._1appFpmx::-ms-input-placeholder{font-size:18.4416px;line-height:22px;color:#999}._1appFpmx::placeholder{font-size:18.4416px;line-height:22px;color:#999}._35fh4ZXz{position:absolute;top:0;left:0;font-size:15.8071px;line-height:19px;color:#999}._1OCAHWpz{overflow-y:hidden;margin-top:34px;height:51px;border:none;border-bottom:1.31726px solid #999;padding:0;font-size:21.0762px;line-height:25px;color:#111;resize:none}._1OCAHWpz:focus{outline:0}._1OCAHWpz::-moz-placeholder{font-size:18.4416px;line-height:22px;color:#999}._1OCAHWpz::-ms-input-placeholder{font-size:18.4416px;line-height:22px;color:#999}._1OCAHWpz::placeholder{font-size:18.4416px;line-height:22px;color:#999}", ""]);
// Exports
exports.locals = {
	"component": "_2aLNPJ8j",
	"input": "_1appFpmx",
	"label": "_35fh4ZXz",
	"textarea": "_1OCAHWpz"
};
module.exports = exports;
