import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import Button from '../../common/button';
import CloseButton from '../../common/close-button';
import InputField from '../../common/input-field';
import UploadField from '../../common/upload-field';

import styles from './styles.less';

const ContactForm = ({onClose, onSubmit}) => {
  const {t} = useTranslation();

  const [topic, setTopic] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);

  const handleSend = () => {
    if (!email) {
      // eslint-disable-next-line no-alert
      alert(t('Please, provide your email'));

      return;
    }

    if (!description) {
      // eslint-disable-next-line no-alert
      alert(t('Please, provide some description'));

      return;
    }

    onSubmit({topic, name, phone, email, description, files}, () => {
      setTopic('');
      setName('');
      setPhone('');
      setEmail('');
      setDescription('');
      setFiles([]);
    });
  };

  return (
    <div className={styles.component}>
      <CloseButton userClassName={styles['close-button']} onClick={onClose}/>
      <div className={styles.title}>{t('Your contact')}</div>
      <div className={styles.fields}>
        <InputField label={t('Topic')} userClassName={styles.field} value={topic} onChange={setTopic}/>
        <InputField label={t('Your name')} userClassName={styles.field} value={name} onChange={setName}/>
        <InputField label={t('Your phone')} userClassName={styles.field} value={phone} onChange={setPhone}/>
        <InputField label={t('Your email')} userClassName={styles.field} value={email} onChange={setEmail}/>
        <InputField
          isMultiline
          label={t('Description')}
          userClassName={styles.field}
          value={description}
          onChange={setDescription}
        />
        <UploadField userClassName={styles['upload-field']} value={files} onChange={setFiles}/>
      </div>
      <Button text={t('Send')} userClassName={styles.button} onClick={handleSend}/>
    </div>
  );
};

ContactForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ContactForm;
