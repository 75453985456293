import classNames from 'classnames';
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

const InputField = ({isMultiline, label, userClassName, value, onChange}) => {
  const textareaRef = useRef(null);

  const [isFocused, setFocused] = useState(false);

  const componentClassNames = classNames(styles.component, userClassName);

  const handleBlur = () => setFocused(false);

  const handleChange = e => onChange(e.target.value);

  const handleFocus = () => setFocused(true);

  const handleInput = () => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  return (
    <div className={componentClassNames}>
      {
        (isFocused || Boolean(value)) && (
          <div className={styles.label}>{label}</div>
        )
      }
      {
        isMultiline ? (
          <textarea
            ref={textareaRef}
            className={styles.textarea}
            placeholder={(isFocused || Boolean(value)) ? null : label}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onInput={handleInput}
          />
        ) : (
          <input
            className={styles.input}
            placeholder={(isFocused || Boolean(value)) ? null : label}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )
      }
    </div>
  );
};

InputField.propTypes = {
  isMultiline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  userClassName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

InputField.defaultProps = {
  isMultiline: false,
  userClassName: null
};

export default InputField;
