import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import {preloadImage} from './lib/helper';

import {createStore} from './redux';

import App from './components/app';

import './css/index.less';

firebase.initializeApp({
  apiKey: 'AIzaSyBK5q3cmDylUzBTMvLpTUEW7h_KciVzq_w',
  authDomain: 'void-walkers.firebaseapp.com',
  databaseURL: 'https://void-walkers.firebaseio.com',
  projectId: 'void-walkers',
  storageBucket: 'void-walkers.appspot.com',
  messagingSenderId: '692517664116',
  appId: '1:692517664116:web:5f4d5198871c7ef6b7e821',
  measurementId: 'G-YXPDFWLB58'
});

const {store, persistor} = createStore();

function render() {
  ReactDOM.render((
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
  ), document.getElementById('app'));
}

const MIN_LOADING_TIME = 1000;

function preloadImages() {
  return Promise.all([
    '/assets/img/background/background-1.png',
    '/assets/img/projects/aloud-ai/preview.jpg',
    '/assets/img/projects/hiero-online/preview.jpg',
    '/assets/img/projects/questcity/preview.jpg',
    '/assets/img/projects/airy-travel/preview.jpg',
    '/assets/img/projects/tuva-music/preview.jpg'
  ].map(preloadImage));
}

function waitForRehydration() {
  return new Promise(resolve => {
    if (store.getState()._persist.rehydrated) {
      resolve();

      return;
    }

    let unsubscribe = null;

    unsubscribe = store.subscribe(() => {
      if (store.getState()._persist.rehydrated) {
        unsubscribe();

        resolve();
      }
    });
  });
}

Promise.all([preloadImages(), waitForRehydration()]).then(() => {
  const {locale} = store.getState().interfaceSettings;

  return i18n
    .use(initReactI18next)
    .init({
      resources: {
        ru: {
          translation: require('./assets/i18n/ru.json')
        }
      },
      lng: locale
    });
}).then(() => {
  const loadingTime = Date.now() - window.loadingStartTimestamp;

  if (loadingTime > MIN_LOADING_TIME) {
    render();
  } else {
    setTimeout(render, MIN_LOADING_TIME - loadingTime);
  }

  Promise.all([
    '/assets/img/projects/aloud-ai/edit-company.png',
    '/assets/img/projects/aloud-ai/edit-order.png',
    '/assets/img/projects/aloud-ai/edit-product.png',
    '/assets/img/projects/aloud-ai/landing.png',
    '/assets/img/projects/aloud-ai/order-details.png',
    '/assets/img/projects/aloud-ai/orders.png',
    '/assets/img/projects/hiero-online/2fa.png',
    '/assets/img/projects/hiero-online/dashboard.png',
    '/assets/img/projects/hiero-online/legal.png',
    '/assets/img/projects/hiero-online/operations.png',
    '/assets/img/projects/hiero-online/support-asset.png',
    '/assets/img/projects/hiero-online/user-asset.png',
    '/assets/img/projects/hiero-online/wallet.png',
    '/assets/img/projects/questcity/case.jpg',
    '/assets/img/projects/airy-travel/case.jpg',
    '/assets/img/projects/tuva-music/case.jpg'
  ].map(preloadImage)).catch(err => console.warn(err));
});
