import localForage from 'localforage';
import {applyMiddleware, createStore as reduxCreateStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

let store = null;

export function createStore() {
  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer({
    key: 'redux-store',
    storage: localForage,
    whitelist: ['interfaceSettings']
  }, require('./reducers').default);

  store = reduxCreateStore(persistedReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  ));
  const persistor = persistStore(store);

  sagaMiddleware.run(require('./sagas').default);

  return {store, persistor};
}
