import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('interface-settings');

const CLEAR = defineAction('CLEAR', subnamespace);
const SET_LOCALE = defineAction('SET_LOCALE', subnamespace);

const clear = createAction(CLEAR.ACTION);
const setLocale = createAction(SET_LOCALE.ACTION);

function _getDefaultLocale() {
  const locale = navigator.language;

  if (locale && locale.startsWith('ru-')) {
    return 'ru';
  }

  return 'en';
}

const INITIAL_STATE = {
  locale: _getDefaultLocale()
};

function handleSetLocale(state, {payload}) {
  return {
    ...state,
    locale: payload
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    CLEAR,
    SET_LOCALE
  },
  actions: {
    clear,
    setLocale
  },
  reducer: createReducer(INITIAL_STATE, {
    [CLEAR.ACTION]: () => ({...INITIAL_STATE}),
    [SET_LOCALE.ACTION]: handleSetLocale
  })
};

export default DUCK;
