import classNames from 'classnames';
import * as firebase from 'firebase/app';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';

import OverlaySpinner from '../overlay-spinner';

import styles from './styles.less';

const UploadField = ({userClassName, value, onChange}) => {
  const {t} = useTranslation();

  const [isUploading, setUploading] = useState(false);

  const componentClassNames = classNames(styles.component, userClassName);

  const handleInputChange = async e => {
    const {files} = e.target;

    if (files.length < 1) {
      return;
    }

    setUploading(true);

    const urls = [];

    try {
      for (const file of files) {
        /* eslint-disable no-await-in-loop */
        const ref = firebase.storage()
          .ref()
          .child(`uploads/${uuid()}-${file.name}`);

        await ref.put(file);

        const url = await ref.getDownloadURL();

        urls.push(url);
        /* eslint-enable no-await-in-loop */
      }

      onChange(urls);
    } catch (err) {
      console.warn(err.message);

      // eslint-disable-next-line no-alert
      alert(err);
    } finally {
      setUploading(false);
    }
  };

  const clear = e => {
    e.preventDefault();
    e.stopPropagation();

    onChange([]);
  };

  return (
    <div className={componentClassNames}>
      <div className={styles['drop-area']}>
        <input
          multiple
          disabled={isUploading}
          title=""
          type="file"
          accept="image/png, image/jpeg, image/gif, application/pdf"
          className={styles.input}
          onChange={isUploading ? null : handleInputChange}
        />
        <div className={styles['drop-area-content']}>
          <img
            alt={t('Upload icon')}
            className={styles['drop-area-image']}
            src={require('../../../assets/img/icons/cloud-upload.svg')}
          />
          <div className={styles['drop-area-text-container']}>
            <div className={styles['drop-area-text']}>
              {(value.length > 0) ? `${t('Files')}: ${value.length}` : t('Upload')}
            </div>
            {
              (value.length > 0) ? (
                <div className={styles['drop-area-delete']} onClick={clear}>{t('Delete')}</div>
              ) : (
                <div className={styles['drop-area-text-sub']}>{t('Drop files here')}</div>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.hint}>{t('only png, jpg, gif or pdf with max size of 30 MB')}</div>
      <OverlaySpinner isVisible={isUploading} text={t('Uploading…')}/>
    </div>
  );
};

UploadField.propTypes = {
  userClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

UploadField.defaultProps = {
  userClassName: null
};

export default UploadField;
