import i18n from 'i18next';

import {call, select, takeLatest} from 'redux-saga/effects';

import InterfaceSettings from '../ducks/interface-settings';

function * handleSetLocale() {
  const locale = yield select(state => state.interfaceSettings.locale);

  yield call([i18n, i18n.changeLanguage], locale);
}

export default function * init() {
  yield takeLatest(InterfaceSettings.actionTypes.SET_LOCALE.ACTION, handleSetLocale);
}
