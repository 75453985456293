// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3oNwaJ5_{margin-left:auto;margin-bottom:30px}._1-_qSUrj{-js-display:flex;display:flex;align-items:center}@media (max-width:900px){._19gUO2gD{display:none}}.O3QXpQ73{display:none}@media (max-width:900px){.O3QXpQ73{display:inline;display:initial}}._3WJISMm-{position:-webkit-sticky;position:sticky;z-index:1;top:10px;height:41px;width:100%}._2CYoRV-Q{position:absolute;top:0;left:0;z-index:3;height:100%;width:100%;-js-display:flex;display:flex;justify-content:space-between;align-items:center}._3SaZ_o74{position:fixed;z-index:2;top:0;left:0;width:100%;background-color:#0e232e}._1sUIlOFy{height:24px;width:24px}.Jd9ptJEm{height:15px;width:22px}._1EviYpKE{font-size:18px;color:#b5b6b9}._1EviYpKE+._1EviYpKE{margin-left:60px}@media (max-width:900px){._1EviYpKE+._1EviYpKE{margin-top:20px;margin-left:0}}.vhgbITT2{color:#b5b6b9;text-decoration:none}.vhgbITT2:hover{color:#fff}._2pQLw9Ty{-js-display:flex;display:flex;align-items:center;cursor:pointer}._2XaAucAN{color:#fff;text-decoration:none}._2XaAucAN ._1njTQMZ2{margin-right:12px;height:41px;width:41px}._2XaAucAN ._3BIxQlWH{position:relative;display:inline-block;font-size:28px;font-weight:700}._2XaAucAN:hover ._3BIxQlWH:after{content:\"\";position:absolute;bottom:3px;left:0;width:100%;height:8px;background:linear-gradient(90deg,rgba(124,75,255,.4),rgba(124,75,255,.2))}._7TacpvaL{padding:8px;cursor:pointer}._1q4U2J5F{position:fixed;z-index:5;top:0;right:-280px;height:100%;width:280px;padding:44px 60px 0 30px;box-sizing:border-box;background-color:#111011;transition:right .5s;display:none}@media (max-width:900px){._1q4U2J5F{display:inline;display:initial}}._1q4U2J5F.wg88mUYi{right:0}", ""]);
// Exports
exports.locals = {
	"close-button": "_3oNwaJ5_",
	"column": "_1-_qSUrj",
	"column-links": "_19gUO2gD",
	"column-menu": "O3QXpQ73",
	"component": "_3WJISMm-",
	"content": "_2CYoRV-Q",
	"header-background": "_3SaZ_o74",
	"image-arrow": "_1sUIlOFy",
	"image-menu": "Jd9ptJEm",
	"item": "_1EviYpKE",
	"link": "vhgbITT2",
	"locale": "_2pQLw9Ty",
	"logo": "_2XaAucAN",
	"logo-image": "_1njTQMZ2",
	"logo-text": "_3BIxQlWH",
	"menu-button": "_7TacpvaL",
	"side-menu": "_1q4U2J5F",
	"side-menu-visible": "wg88mUYi"
};
module.exports = exports;
