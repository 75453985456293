// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1usPm--H{text-align:center;font-family:Rubik,sans-serif;font-size:18px;font-weight:700;color:#fff;white-space:nowrap;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-appearance:none;-moz-appearance:none;box-shadow:0 3px 6px 0 rgba(0,0,0,.4),0 5px 15px 0 rgba(0,0,0,.38);transition:transform .5s,box-shadow .5s;border-radius:10px 0;height:60px;padding:0 47px;border:none}._1usPm--H:focus{outline:0}._1usPm--H._2-55j450{background-color:#08c666}._1usPm--H._1cFVo07n{background-color:#ffc501}._1usPm--H:hover{box-shadow:0 4px 8px 0 rgba(0,0,0,.4),0 6px 20px 0 rgba(0,0,0,.38);transform:translate(2px,-2px);cursor:pointer}", ""]);
// Exports
exports.locals = {
	"button": "_1usPm--H",
	"primary": "_2-55j450",
	"primary-alternate": "_1cFVo07n"
};
module.exports = exports;
