import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {findElement} from '../../../../lib/helper';

import InterfaceSettings from '../../../../redux/ducks/interface-settings';

import styles from './styles.less';

const LOCALE_MAP = new Map([
  ['en', 'Eng'],
  ['ru', 'Рус']
]);

const LocaleSwitch = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {locale} = useSelector(state => state.interfaceSettings);

  const componentRef = useRef(null);

  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenuVisibility = () => setMenuVisible(!isMenuVisible);

  useEffect(() => {
    const handleClickOutside = e => {
      if (findElement(e.target, componentRef.current)) {
        return;
      }

      setMenuVisible(false);
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const selectLocale = l => () => {
    dispatch(InterfaceSettings.actions.setLocale(l));

    setMenuVisible(false);
  };

  const menuClassNames = classNames({
    [styles.menu]: true,
    [styles['menu-visible']]: isMenuVisible
  });

  return (
    <div ref={componentRef} className={styles.component} onClick={toggleMenuVisibility}>
      {LOCALE_MAP.get(locale)}
      <img
        alt={t('Arrow down')}
        className={styles['image-arrow']}
        src={require('../../../../assets/img/icons/arrow-drop-down.svg')}
      />
      <div className={menuClassNames}>
        <div className={styles.item} onClick={selectLocale('en')}>English</div>
        <div className={styles.item} onClick={selectLocale('ru')}>Русский</div>
      </div>
    </div>
  );
};

export default LocaleSwitch;
