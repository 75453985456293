import React from 'react';
import * as PropTypes from 'prop-types';

import Loader from '../loader';

import styles from './styles.less';

const OverlaySpinner = ({isVisible, text}) => {
  const componentStyle = isVisible ? {} : {display: 'none'};

  return (
    <div className={styles.component} style={componentStyle}>
      <Loader text={text}/>
    </div>
  );
};

OverlaySpinner.propTypes = {
  isVisible: PropTypes.bool,
  text: PropTypes.string
};

OverlaySpinner.defaultProps = {
  isVisible: false,
  text: 'Loading...'
};

export default OverlaySpinner;
