import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';

import CloseButton from '../../common/close-button';

import LocaleSwitch from './locale-switch';

import styles from './styles.less';

const HEADER_HEIGHT = 41;
const HEADER_MARGIN = 44;
const HEADER_BACKGROUND_EXTRA_HEIGHT = 19;

const HEADER_BACKGROUND_MAX_HEIGHT = HEADER_HEIGHT + HEADER_MARGIN + HEADER_BACKGROUND_EXTRA_HEIGHT;
const HEADER_BACKGROUND_MIN_HEIGHT = HEADER_HEIGHT + HEADER_BACKGROUND_EXTRA_HEIGHT;

function _getHeaderBackgroundHeight(pageScrollTop) {
  return Math.max(HEADER_BACKGROUND_MAX_HEIGHT - pageScrollTop, HEADER_BACKGROUND_MIN_HEIGHT);
}

function _getHeaderBackgroundOpacity(pageScrollTop) {
  return 1 -
    ((_getHeaderBackgroundHeight(pageScrollTop) - HEADER_BACKGROUND_MIN_HEIGHT) / HEADER_BACKGROUND_MAX_HEIGHT);
}

const root = window.document.querySelector(':root');

const Header = () => {
  const {t} = useTranslation();

  const [pageScrollTop, setPageScrollTop] = useState(root.scrollTop);
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const {scrollTop} = root;

      setPageScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sideMenuClassNames = classNames({
    [styles['side-menu']]: true,
    [styles['side-menu-visible']]: isSideMenuVisible
  });

  const toggleSideMenuVisibility = () => setSideMenuVisible(!isSideMenuVisible);

  const backgroundStyle = {
    display: (pageScrollTop > 0) ? 'initial' : 'none',
    height: `${_getHeaderBackgroundHeight(pageScrollTop)}px`,
    opacity: _getHeaderBackgroundOpacity(pageScrollTop)
  };

  return (
    <div className={styles.component}>
      <div className={styles['header-background']} style={backgroundStyle}/>
      <div className={styles.content}>
        <a className={`${styles.column} ${styles.logo}`} href="/">
          <img alt={t('Logo')} className={styles['logo-image']} src={require('../../../assets/img/logo/logo.svg')}/>
          <span className={styles['logo-text']}>Void Walkers</span>
        </a>
        <div className={`${styles.column} ${styles['column-links']}`}>
          <div className={styles.item}>
            <a href="#activities" className={styles.link}>{t('What we do')}</a>
          </div>
          <div className={styles.item}>
            <a href="#projects" className={styles.link}>{t('Projects')}</a>
          </div>
          <div className={styles.item}>
            <a href="#contacts" className={styles.link}>{t('Contacts')}</a>
          </div>
          <div className={styles.item}>
            <LocaleSwitch/>
          </div>
        </div>
        <div className={`${styles.column} ${styles['column-menu']}`}>
          <div className={styles.item}>
            <div className={styles['menu-button']} onClick={toggleSideMenuVisibility}>
              <img
                alt={t('Menu icon')}
                className={styles['image-menu']}
                src={require('../../../assets/img/icons/menu.svg')}
              />
            </div>
          </div>
        </div>
        <div className={sideMenuClassNames}>
          <CloseButton userClassName={styles['close-button']} onClick={toggleSideMenuVisibility}/>
          <div className={styles.item}>
            <a href="#activities" className={styles.link} onClick={toggleSideMenuVisibility}>{t('What we do')}</a>
          </div>
          <div className={styles.item}>
            <a href="#projects" className={styles.link} onClick={toggleSideMenuVisibility}>{t('Projects')}</a>
          </div>
          <div className={styles.item}>
            <a href="#contacts" className={styles.link} onClick={toggleSideMenuVisibility}>{t('Contacts')}</a>
          </div>
          <div className={styles.item}>
            <LocaleSwitch/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
