import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import CloseButton from '../close-button';

import styles from './styles.less';

const root = window.document.querySelector(':root');

const ProjectOverlay = ({images, title, onClose}) => {
  const {t} = useTranslation();

  const componentRef = useRef(null);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const currentImage = images[currentImageIndex];

  const imageWrapperClassNames = classNames({
    [styles['image-wrapper']]: true,
    [styles['image-wrapper-case']]: currentImage.isCase
  });

  const nextImage = () => {
    if (currentImageIndex >= (images.length - 1)) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const previousImage = () => {
    if (currentImageIndex <= 0) {
      setCurrentImageIndex(images.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  useEffect(() => {
    root.style.overflow = 'hidden';

    return () => {
      root.style.overflow = 'initial';
    };
  }, []);

  useEffect(() => {
    const component = componentRef.current;

    if (!component) {
      return;
    }

    const handleKeyDown = e => {
      switch (e.key) {
      case 'ArrowLeft':
        previousImage();

        break;
      case 'ArrowRight':
        nextImage();

        break;
      case 'Escape':
        onClose();

        break;
      default:
        break;
      }
    };

    if (component) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (component) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [currentImageIndex]);

  return (
    <div ref={componentRef} className={styles.component}>
      <div className={imageWrapperClassNames}>
        <img alt={currentImage.title} className={styles.image} src={currentImage.url}/>
      </div>
      {
        (images.length > 1) && (
          <>
            <div className={`${styles['arrow-button']} ${styles['arrow-button-left']}`} onClick={previousImage}>
              <img
                alt={t('Arrow left')}
                className={styles['image-arrow']}
                src={require('../../../assets/img/icons/arrow-left.svg')}
              />
            </div>
            <div className={`${styles['arrow-button']} ${styles['arrow-button-right']}`} onClick={nextImage}>
              <img
                alt={t('Arrow right')}
                className={styles['image-arrow']}
                src={require('../../../assets/img/icons/arrow-right.svg')}
              />
            </div>
          </>
        )
      }
      <CloseButton userClassName={styles['close-button']} onClick={onClose}/>
      <div className={styles.title}>{title + (currentImage.title ? ' — ' : '') + (currentImage.title || '')}</div>
    </div>
  );
};

ProjectOverlay.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    isCase: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProjectOverlay;
