import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

const CloseButton = ({userClassName, onClick}) => {
  return (
    <div className={classNames(styles.component, userClassName)} onClick={onClick}/>
  );
};

CloseButton.propTypes = {
  userClassName: PropTypes.string,
  onClick: PropTypes.func
};

CloseButton.defaultProps = {
  userClassName: null,
  onClick: null
};

export default CloseButton;
