// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2w51Xw0r{padding:53px 63px 42px 45px;box-sizing:border-box;background-color:#fff;border-radius:6px;cursor:default}@media (max-width:800px){._2w51Xw0r{-js-display:flex;display:flex;flex-direction:column;align-items:center;height:100%;width:100%;border-radius:0;padding:25px 30px 20px 22px;overflow-y:scroll}}@media (max-height:850px){._2w51Xw0r{-js-display:flex;display:flex;flex-direction:column;align-items:center;height:100%;width:100%;border-radius:0;padding:25px 30px 20px 22px;overflow-y:scroll}}._2_jySa2_{position:fixed;z-index:1000;top:0;left:0;-js-display:flex;display:flex;justify-content:center;align-items:center;height:100%;width:100%;background-color:rgba(35,35,35,.9);cursor:pointer}", ""]);
// Exports
exports.locals = {
	"modal": "_2w51Xw0r",
	"overlay": "_2_jySa2_"
};
module.exports = exports;
