import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.less';

const Button = ({kind, text, userClassName, userStyle, onClick}) => {
  const buttonClassNames = classNames({
    [userClassName]: Boolean(userClassName),
    [styles.button]: true,
    [styles.primary]: kind === Button.KIND.PRIMARY,
    [styles['primary-alternate']]: kind === Button.KIND.PRIMARY_ALTERNATE
  });

  const clickHandler = e => {
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button className={buttonClassNames} style={userStyle} type="button" onClick={clickHandler}>{text}</button>
  );
};

Button.KIND = {
  PRIMARY: 'primary',
  PRIMARY_ALTERNATE: 'primary_alternate'
};

Button.propTypes = {
  kind: PropTypes.oneOf(Object.values(Button.KIND)),
  text: PropTypes.string.isRequired,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

Button.defaultProps = {
  kind: Button.KIND.PRIMARY,
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default Button;
