// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Ezm377R{position:relative;height:32px;width:32px;cursor:pointer;opacity:.7}._2Ezm377R:hover{opacity:1}._2Ezm377R:after{transform:rotate(-45deg)}._2Ezm377R:after,._2Ezm377R:before{position:absolute;left:15px;content:\" \";height:33px;width:2px;background-color:#fff}._2Ezm377R:before{transform:rotate(45deg)}", ""]);
// Exports
exports.locals = {
	"component": "_2Ezm377R"
};
module.exports = exports;
