// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BO0PWs0e{position:absolute;top:0;height:100vh;padding:0 10px;box-sizing:border-box;cursor:pointer;background-color:initial;-js-display:flex;display:flex;flex-direction:column;justify-content:center}.BO0PWs0e .KQ8Mdlk6{opacity:.8;height:24px;width:24px;filter:invert(1)}.BO0PWs0e:hover{background-color:rgba(14,35,46,.7)}.BO0PWs0e:hover .KQ8Mdlk6{opacity:1}.BO0PWs0e.hAv2UrlR{left:0}.BO0PWs0e._3_AuWJ7u{right:0}._1dv2CwCz{position:absolute;top:32px;right:64px;background-color:rgba(17,16,17,.2)}._3V5P_G2N{position:fixed;z-index:1000;top:0;left:0;background-color:rgba(35,35,35,.9)}._3V5P_G2N,.mDasQmgg{height:100%;width:100%}.mDasQmgg>._1f2eUsFv{width:100%}.mDasQmgg:not(._3y5hfde4){-js-display:flex;display:flex;align-items:center}.mDasQmgg:not(._3y5hfde4)>._1f2eUsFv{object-fit:cover;object-position:top center}.mDasQmgg._3y5hfde4{overflow-y:auto}._32t07eb5{position:absolute;bottom:0;left:0;height:80px;width:100%;padding:0 20px;box-sizing:border-box;-js-display:flex;display:flex;justify-content:center;align-items:center;background:linear-gradient(180deg,transparent,#111011);font-size:28px;font-weight:700;color:#ffc501}@media (max-width:1080px){._32t07eb5{font-size:24px}}@media (max-width:600px){._32t07eb5{font-size:20px}}", ""]);
// Exports
exports.locals = {
	"arrow-button": "BO0PWs0e",
	"image-arrow": "KQ8Mdlk6",
	"arrow-button-left": "hAv2UrlR",
	"arrow-button-right": "_3_AuWJ7u",
	"close-button": "_1dv2CwCz",
	"component": "_3V5P_G2N",
	"image-wrapper": "mDasQmgg",
	"image": "_1f2eUsFv",
	"image-wrapper-case": "_3y5hfde4",
	"title": "_32t07eb5"
};
module.exports = exports;
