import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.less';

export const LoaderSize = {
  Normal: 'normal',
  Small: 'small'
};

const Loader = ({size, text}) => {
  const spinnerClassNames = classNames({
    [styles.spinner]: true,
    [styles['component-normal']]: size === LoaderSize.Normal,
    [styles['component-small']]: size === LoaderSize.Small
  });

  return (
    <div className={styles.component}>
      <div className={spinnerClassNames}/>
      {
        Boolean(text) && (
          <div className={styles.text}>{text}</div>
        )
      }
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(Object.values(LoaderSize)),
  text: PropTypes.string
};

Loader.defaultProps = {
  size: LoaderSize.Normal,
  text: 'Loading...'
};

export default Loader;
