import * as ReactDOM from 'react-dom';

export function findElement(target, element) {
  let el = target;

  while (el) {
    if (el === element) {
      return true;
    }

    el = el.parentNode;
  }

  return false;
}

export function measureElement(element) {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(element);

  return {
    width: node.offsetWidth,
    height: node.offsetHeight
  };
}

export function preloadImage(url) {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = () => resolve();
    img.onerror = err => {
      console.warn(err);

      resolve();
    };

    img.src = url;
  });
}
