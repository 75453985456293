import * as firebase from 'firebase/app';
import {useTranslation} from 'react-i18next';
import React, {useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';

import {Color} from '../../lib/constants';

import useElementSize from '../../hooks/use-element-size';
import useModal from '../../hooks/use-modal';

import Button from '../common/button';
import Modal from '../common/modal';
import OverlaySpinner from '../common/overlay-spinner';
import ProjectOverlay from '../common/project-overlay';

import ContactForm from './contact-form';
import Header from './header';

import styles from './styles.less';

const App = () => {
  const {t} = useTranslation();

  const PROJECTS = [{
    title: 'Aloud AI',
    images: [{
      title: t('Landing page'),
      url: require('../../assets/img/projects/aloud-ai/landing.png'),
      isCase: true
    }, {
      title: t('Order list'),
      url: require('../../assets/img/projects/aloud-ai/orders.png')
    }, {
      title: t('Order details'),
      url: require('../../assets/img/projects/aloud-ai/order-details.png')
    }, {
      title: t('Edit order'),
      url: require('../../assets/img/projects/aloud-ai/edit-order.png')
    }, {
      title: t('Product list'),
      url: require('../../assets/img/projects/aloud-ai/products.png')
    }, {
      title: t('Edit product'),
      url: require('../../assets/img/projects/aloud-ai/edit-product.png')
    }, {
      title: t('Edit company'),
      url: require('../../assets/img/projects/aloud-ai/edit-company.png')
    }]
  }, {
    title: 'Hiero.online',
    images: [{
      title: t('Dashboard'),
      url: require('../../assets/img/projects/hiero-online/dashboard.png')
    }, {
      title: t('Operation history'),
      url: require('../../assets/img/projects/hiero-online/operations.png')
    }, {
      title: t('Asset info'),
      url: require('../../assets/img/projects/hiero-online/user-asset.png')
    }, {
      title: t('Wallet'),
      url: require('../../assets/img/projects/hiero-online/wallet.png')
    }, {
      title: t('Legal section'),
      url: require('../../assets/img/projects/hiero-online/legal.png')
    }, {
      title: t('Two-factor authentication'),
      url: require('../../assets/img/projects/hiero-online/2fa.png')
    }, {
      title: t('Support chat & asset on market'),
      url: require('../../assets/img/projects/hiero-online/support-asset.png')
    }]
  }, {
    title: 'Questcity',
    images: [{
      url: require('../../assets/img/projects/questcity/case.jpg'),
      isCase: true
    }]
  }, {
    title: 'Airy.Travel',
    images: [{
      url: require('../../assets/img/projects/airy-travel/case.jpg'),
      isCase: true
    }]
  }, {
    title: 'Tuva Music',
    images: [{
      url: require('../../assets/img/projects/tuva-music/case.jpg'),
      isCase: true
    }]
  }];

  const [modalProps, {closeModal, openModal}] = useModal();

  const [isSending, setSending] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);

  const closeProjectOverlay = () => setSelectedProjectIndex(-1);

  const contentWrapperRef = useRef(null);

  const [{height: contentWrapperHeight}] = useElementSize(contentWrapperRef);

  const handleSubmit = async (data, clear) => {
    setSending(true);

    try {
      const collection = firebase.firestore().collection('contacts');

      const date = new Date();

      const ref = collection.doc(`${date.toISOString()}-${uuid()}`);

      await ref.set({
        ...data,
        date
      });

      // eslint-disable-next-line no-alert
      alert(t('We have received your request and will contact you soon') + '. ' + t('Thanks!'));

      clear();

      closeModal();
    } catch (err) {
      console.warn(err.message);

      // eslint-disable-next-line no-alert
      alert(err);
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
      <div className={styles.background} style={{height: `${contentWrapperHeight}px`}}>
        <div className={styles['background-1']}>
          <img
            alt={t('Background image')}
            className={styles['background-1-image']}
            src={require('../../assets/img/background/background-1.png')}
          />
        </div>
        <div className={styles['background-1-bottom']}/>
        <img
          alt={t('Background image')}
          className={styles['background-2-image']}
          src={require('../../assets/img/background/background-2.png')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-3-image']}
          src={require('../../assets/img/background/background-3.png')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-4-image']}
          src={require('../../assets/img/background/background-4.png')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-5-image']}
          src={require('../../assets/img/background/background-5.svg')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-6-image']}
          src={require('../../assets/img/background/background-6.svg')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-7-image']}
          src={require('../../assets/img/background/background-7.svg')}
        />
        <img
          alt={t('Background image')}
          className={styles['background-8-image']}
          src={require('../../assets/img/background/background-8.svg')}
        />
        <div className={styles['dots-1-image']}/>
        <div className={styles['dots-2-image']}/>
      </div>
      <div ref={contentWrapperRef} className={styles['content-wrapper']}>
        <div className={styles.content}>
          <Header/>
          <div className={styles.tagline}>
            {t('Have an idea?')}<br/>{t('We bring it to life')}.
          </div>
          <div className={styles.description}>
            {t('We develop sites, apps and services')}. {t('No boring templated online stores')}
            {' — '}
            <br/>{t('unique solution for every client')}. {t('Our goal is to solve your problem')}.
          </div>
          <Button text={t('Get Started')} userClassName={styles['button-get-started']} onClick={openModal}/>
          <div id="activities" className={styles.services}>
            <div className={styles.service}>
              <div className={styles['service-caption']}>{t('Advisory & Ideation')}</div>
              <div className={styles['service-line']}/>
              <div className={styles['service-body']}>
                {t('We turn your ideas into specification and start working on it immediately')}.<br/>
                {t('We help you solve the problem efficiently and minimize costs')}.<br/>
                {t('We provide you with options based on your budget')}.
              </div>
            </div>
            <div className={styles.service}>
              <div className={styles['service-caption']}>{t('Development')}</div>
              <div className={styles['service-line']}/>
              <div className={styles['service-body']}>
                {t('You get first working version of your project in 2 months')}.<br/>
                {t('We use modern tools and technologies only and never cut down on quality')}.<br/>
                {t('Finding a balance between application reliability')}{', '}
                {t('code quality and time of development is our core skill')}.
              </div>
            </div>
            <div className={styles.service}>
              <div className={styles['service-caption']}>{t('Maintenance')}</div>
              <div className={styles['service-line']}/>
              <div className={styles['service-body']}>
                {t('We focus on long-term partnership, so our loyal customers are always welcome')}{'. '}
                {t('Usually our apps and sites don\'t  require any special maintenance, they just work')}{'. '}
                {t('For complex systems we are happy to consider terms of continuous technical support')}.
              </div>
            </div>
          </div>
          <div className={styles.features}>
            <div className={styles['features-left']}>
              {t('We')}<br/>
              {t('create')}<br/>
              {t('Projects')}
            </div>
            <div className={styles['features-right']}>
              <div className={styles.feature}>
                <span className={styles['feature-accent']} style={{color: Color.Accent}}>{t('Fast web apps')}</span>
                {t(' supporting all modern browsers, desktop and mobile;')}
              </div>
              <div className={styles.feature}>
                {t('Native looking, ')}
                <span
                  className={styles['feature-accent']}
                  style={{color: Color.AccentAlternate}}
                >
                  {t('cross-platform mobile apps')}
                </span>
                {' (Android/iOS);'}
              </div>
              <div className={styles.feature}>
                <span
                  className={styles['feature-accent']}
                  style={{color: Color.AccentAlternate3}}
                >
                  {t('Complex solutions')}
                </span>
                {t(' with custom business logic backed by servers and databases')}.
              </div>
            </div>
          </div>
          <div id="projects" className={styles.projects}>
            <div className={styles.project}>
              <img
                alt={t('Project image')}
                className={styles['project-image']}
                src={require('../../assets/img/projects/aloud-ai/preview.jpg')}
              />
              <div className={styles['project-frame']} onClick={() => setSelectedProjectIndex(0)}>
                <div className={styles['project-info']}>
                  <div className={styles['project-name']}>Aloud AI</div>
                  <div className={styles['project-description']}>
                    {t('The platform enables partner restaurants to receive orders via Amazon Alexa')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.project}>
              <img
                alt={t('Project image')}
                className={styles['project-image']}
                src={require('../../assets/img/projects/hiero-online/preview.jpg')}
              />
              <div className={styles['project-frame']} onClick={() => setSelectedProjectIndex(1)}>
                <div className={styles['project-info']}>
                  <div className={styles['project-name']}>Hiero.online</div>
                  <div className={styles['project-description']}>
                    {t('Enables users to participate in IPOs by following programs and events led by a trusted portfolio manager')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.project}>
              <img
                alt={t('Project image')}
                className={styles['project-image']}
                src={require('../../assets/img/projects/questcity/preview.jpg')}
              />
              <div className={styles['project-frame']} onClick={() => setSelectedProjectIndex(2)}>
                <div className={styles['project-info']}>
                  <div className={styles['project-name']}>Questcity</div>
                  <div className={styles['project-description']}>
                    {t('Quest-tours of the city in reality through the mobile application')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.project}>
              <img
                alt={t('Project image')}
                className={styles['project-image']}
                src={require('../../assets/img/projects/airy-travel/preview.jpg')}
              />
              <div className={styles['project-frame']} onClick={() => setSelectedProjectIndex(3)}>
                <div className={styles['project-info']}>
                  <div className={styles['project-name']}>Airy.Travel</div>
                  <div className={styles['project-description']}>
                    {t('Air tickets search & hotel booking service')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.project}>
              <img
                alt={t('Project image')}
                className={styles['project-image']}
                src={require('../../assets/img/projects/tuva-music/preview.jpg')}
              />
              <div className={styles['project-frame']} onClick={() => setSelectedProjectIndex(4)}>
                <div className={styles['project-info']}>
                  <div className={styles['project-name']}>Tuva Music</div>
                  <div className={styles['project-description']}>
                    {t('Tuva artists music application')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="contacts" className={styles['contact-us']}>{t('Contact us')}</div>
          <Button
            kind={Button.KIND.PRIMARY_ALTERNATE}
            text={t('Get in touch')}
            userClassName={styles['button-get-in-touch']}
            onClick={openModal}
          />
          <div className={styles['phone-container']}>
            <div>
              <a className={styles['phone-link']} href="tel:+79956765561">{t('Call')}</a>
            </div>
            <div>
              <a className={styles['phone-link']} href="tel:+79956765561">+79956765561</a>
            </div>
          </div>
          <div className={styles['social-container']}>
            <div className={styles['social-title']}>{t('Social')}</div>
            <div className={styles['social-list']}>
              <a className={styles['social-item']} href="https://t.me/voidwalkers" rel="noreferrer" target="_blank">
                <img
                  alt={t('Telegram icon')}
                  className={styles['social-item-image']}
                  src={require('../../assets/img/icons/telegram.svg')}
                  title={t('Telegram')}
                />
              </a>
              <a className={styles['social-item']} href="mailto:admin@void-walkers.com">
                <img
                  alt={t('Email icon')}
                  className={styles['social-item-image']}
                  src={require('../../assets/img/icons/email.svg')}
                  title={t('Email')}
                />
              </a>
              <a className={styles['social-item']} href="https://vk.com/voidwalkers" rel="noreferrer" target="_blank">
                <img
                  alt={t('VKontakte icon')}
                  className={styles['social-item-image']}
                  src={require('../../assets/img/icons/vkontakte.svg')}
                  title={t('VKontakte')}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal {...modalProps}>
        <ContactForm onClose={closeModal} onSubmit={handleSubmit}/>
      </Modal>
      {
        (selectedProjectIndex >= 0) && (
          <ProjectOverlay
            images={PROJECTS[selectedProjectIndex].images}
            title={PROJECTS[selectedProjectIndex].title}
            onClose={closeProjectOverlay}
          />
        )
      }
      <OverlaySpinner isVisible={isSending} text={t('Sending…')}/>
    </div>
  );
};

export default App;
