// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1GxKW8PK{position:relative;-js-display:flex;display:flex;align-items:center;cursor:pointer}._1GxKW8PK:hover{color:#fff}._1InZanVI{color:#b5b6b9;cursor:pointer}._1InZanVI:hover{color:#fff}._1InZanVI+._1InZanVI{margin-top:20px}._25_dK088{position:absolute;top:100%;right:0;padding:20px 30px 20px 20px;border-radius:6px;border:1px solid #232323;background-color:#111011;display:none}._25_dK088.VN0qTXxI{display:inline;display:initial}", ""]);
// Exports
exports.locals = {
	"component": "_1GxKW8PK",
	"item": "_1InZanVI",
	"menu": "_25_dK088",
	"menu-visible": "VN0qTXxI"
};
module.exports = exports;
