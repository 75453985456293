import {useCallback, useState} from 'react';

const useModal = ({isCloseOnClickDisabled, isVisible = false} = {}) => {
  const [isModalVisible, setModalVisible] = useState(isVisible);

  const closeModal = useCallback(() => setModalVisible(false), []);

  const openModal = useCallback(() => setModalVisible(true), []);

  return [{
    isCloseOnClickDisabled,
    isVisible: isModalVisible,
    onCloseRequested: closeModal
  }, {closeModal, openModal}];
};

export default useModal;
