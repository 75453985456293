// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._348Eq-gp{width:100%;-js-display:flex;display:flex;flex-direction:column;align-items:flex-end}._2wVTVXJ0{position:relative;height:79px;width:100%;padding:22px 0 15px 25px;border:1.31726px dashed #999;box-sizing:border-box;border-radius:2.63452px;cursor:pointer}._2dxkBsAG{-js-display:flex;display:flex;align-items:center}._1y4ALw3s{margin-right:17px;width:40px;height:30px}._3otA1csK{font-size:18.4416px;line-height:22px;color:#999}._3w_NjIHB{-js-display:flex;display:flex;flex-direction:column;justify-content:space-between}.WORyxiNe{font-size:13.1726px;line-height:16px;color:#ff2d5c;cursor:pointer;z-index:1}.msJld07H{color:#999}.msJld07H,._2nV7Imu0{font-size:13.1726px;line-height:16px}._2nV7Imu0{margin-top:11px;font-style:italic;color:#ccc}._2Sujcqvj{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"component": "_348Eq-gp",
	"drop-area": "_2wVTVXJ0",
	"drop-area-content": "_2dxkBsAG",
	"drop-area-image": "_1y4ALw3s",
	"drop-area-text": "_3otA1csK",
	"drop-area-text-container": "_3w_NjIHB",
	"drop-area-delete": "WORyxiNe",
	"drop-area-text-sub": "msJld07H",
	"hint": "_2nV7Imu0",
	"input": "_2Sujcqvj"
};
module.exports = exports;
